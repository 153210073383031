@import './variables.less';

* {
padding: 0;
margin: 0;
}

a {
  color: @textColor;
  text-decoration: none;
}

img {
  vertical-align: top;
}