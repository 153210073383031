  /*整个滚动条*/ 
  ::-webkit-scrollbar {
 
    width: 9px;
   
    height: 8px;
   
    /* background-color: #fff; */
   
   }
   
   /*定义滚动条轨道*/
   
   ::-webkit-scrollbar-track {
   
    /* background-color: #fff; */
   
   }
   
   /*定义滑块*/
   
   ::-webkit-scrollbar-thumb {
   
    background-color: #A8A7A7;
    border-radius: 4px;
   }

   ::-webkit-scrollbar-thumb:hover {
    background-color: #6a6767;
   }